import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MDBox";
import MDBox from "components/MDBox";
import MKTypography from "components/MDTypography";

import {useEffect, useRef} from "react";
import Typed from "typed.js";

// Material Kit 2 PRO React examples
import DefaultBackgroundCard from "./components/DefaultBackgroundCard";
import ListItem from "./components/ListItem";
import bgImage1 from "assets/images/ExcitedAppBack.png";
import bgImage from "assets/images/back9.png";
import TrackingScriptLoader from "../../util/TrackingScriptLoader";
import AdvisorForm from "./components/AdvisorForm";

function Advisors() {
    const typedJSRef = useRef(null);
    useEffect(() => {
        const typedJS = new Typed(typedJSRef.current, {
            strings: ["for quality clients", "for new business", "to build your empire"],
            typeSpeed: 50,
            backSpeed: 60,
            backDelay: 200,
            startDelay: 500,
            loop: true,
        });

        return () => typedJS.destroy();
    }, []);

    const handleScrollToSignup = () => {
        const signupElement = document.getElementById("signup-section");
        if (signupElement) {
            const offset = 100;
            const elementPosition = signupElement.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = elementPosition - offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    };

    return (
        <>
            <TrackingScriptLoader
                id="facebook-tracking"
                inlineScript={`
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '1952083521871808');
fbq('track', 'PageView');
        `}
            />
            <MKBox
                minHeight="70vh"
                width="100%"
                sx={{
                    backgroundImage: `url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid container item xs={12} lg={7} justifyContent="center" mx="auto" mt={9}>
                        <MKTypography
                            variant="h2"
                            color="white"
                            sx={({breakpoints, typography: {size}}) => ({
                                [breakpoints.down("md")]: {
                                    fontSize: size["3xl"],
                                },
                                textAlign: "center",
                            })}
                        >
                            Are you a <MKTypography component="span" variant="h2" color="dark"
                                                    display="inline">long-term risk and investment
                            accredited</MKTypography> financial advisor looking <br/>
                            <span ref={typedJSRef}/>
                        </MKTypography>
                        <MDBox textAlign="center">
                            <MKTypography color="dark" variant='h6' mb={1}>
                                Are you a financial advisor that can sell long-term insurance and retirement annuities,
                                ready to transform
                                your career and business? If
                                you're dedicated to delivering outstanding guidance and searching for exceptional
                                clients to work with, we might have the perfect opportunity waiting for you!
                            </MKTypography>
                            <MKTypography color="white" variant='h6'>
                                Scroll down for more information
                            </MKTypography>
                        </MDBox>
                    </Grid>
                </Container>
            </MKBox>
            <MKBox component="section" py={12}>
                <Container>
                    <Grid
                        container
                        item
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        xs={10}
                        lg={5}
                        sx={{mx: "auto", textAlign: "center"}}
                    >
                        <MKTypography variant="h3" mt={1} color='dark'>
                            You can enjoy some of the best sales conversion rates in the industry by servicing our
                            clients.
                        </MKTypography>
                        <MKTypography variant="h4" mt={1} color='info'>
                            We are picky who we work with, but you might just be a good fit.
                        </MKTypography>
                        <MKTypography variant="body2" color="text">
                            We only work with quality advisors who are passionate about helping clients improve their
                            finances and who are hungry for success. Those we work with benefit from the following:
                        </MKTypography>
                    </Grid>
                    <Grid container spacing={3} alignItems="center" sx={{mt: 6}}>
                        <Grid item xs={12} md={4} sx={{ml: "auto"}}>
                            <DefaultBackgroundCard
                                image={bgImage1}
                                label="Technology"
                                title="Uses the latest technology"
                                description="Our cutting-edge app combines the latest technology with exceptional advisors like you to help clients achieve their financial dreams and reach their full potential."
                                action={{
                                    type: "internal",
                                    label: "Get Started",
                                    action: handleScrollToSignup
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={5} sx={{mr: "auto", ml: {xs: 0, md: 6}}}>
                            <ListItem title="You do your thing.">
                                We're proud to partner with reputable advisors and brokers - this isn't an employment
                                offer, but something better! You'll maintain full independence, operating your own
                                practice under your own or your principal's FSP, with complete freedom to run your
                                business your way.
                            </ListItem>
                            <ListItem title="Great tools">
                                Our cutting-edge system equips you with state-of-the-art tools that unlock unprecedented
                                insights into your clients' needs. This empowers you to deliver exceptional,
                                value-driven advice that truly transforms your clients' financial future.
                            </ListItem>
                            <ListItem title="Access to clients">
                                We never sell leads or client information - instead, we build lasting relationships with
                                clients through our app and connect them with quality advisors like you. We've created a
                                secure environment with clear guidelines that protects both our clients and financial
                                partners, ensuring everyone wins.
                            </ListItem>
                            <ListItem title="We don't split comissions">
                                Your commissions stay 100% yours - no splits or surprises! We simply charge a small
                                platform fee to our carefully selected advisors, plus a fee for each client mandate we
                                connect you with. This means you keep earning great money while we serve as your tech
                                partner and reliable source of quality clients.
                            </ListItem>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
            <MKBox id="signup-section" component="section" py={5} bgColor="white">
                <Container>
                    <Grid
                        container
                        item
                        justifyContent="center"
                        xs={10}
                        lg={7}
                        mx="auto"
                        textAlign="center"
                    >
                        <MKTypography variant="h3" mb={1} color='info'>
                            Talk to one of our team members today to boost your business
                        </MKTypography>
                        <MKTypography variant="body2" color="text">
                            Complete the form below.
                        </MKTypography>
                        <MKTypography variant="h6" mb={1} color='error'>
                            Do not complete this form if you are not a registered financial advisor that is accredited
                            to sell long-term risk and retirement annuity products.
                        </MKTypography>
                    </Grid>
                </Container>
                <AdvisorForm/>
            </MKBox>
        </>
    );
}

export default Advisors;