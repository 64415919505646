import React, {useState} from 'react';
import axios from 'axios';
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import {parsePhoneNumberFromString} from 'libphonenumber-js';

// Material Kit 2 PRO React components
import MDBox from "components/MDBox";
import MKInput from "components/MDInput";
import MKButton from "components/MDButton";
import MKTypography from "components/MDTypography";
import {loadScript} from "../../../util/TrackingScriptLoader";
import Checkbox from '@mui/material/Checkbox';


const AdvisorForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        phone: '',
        email: '',
        affiliation: 'tied',
        fspNumber: '',
        underSupervision: '',
        isAccreditedAdvisor: false
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [phoneError, setPhoneError] = useState("");
    const [emailError, setEmailError] = useState("");

    const handleChange = (e) => {
        const {name, value} = e.target;
        if (name === 'phone') {
            let phoneValue = value;
            if (phoneValue.length === 10 && phoneValue.startsWith('0')) {
                phoneValue = `+27${phoneValue.substring(1)}`;
            }
            setFormData(prevState => ({
                ...prevState,
                [name]: phoneValue
            }));
            validatePhoneNumber(phoneValue);
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
            if (name === 'email') {
                validateEmail(value);
            }
        }
    };

    const validatePhoneNumber = (value) => {
        if (value) {
            const phoneNumber = parsePhoneNumberFromString(value, 'ZA');
            if (!phoneNumber || (phoneNumber.nationalNumber.length !== 9 && phoneNumber.number.length !== 12)) {
                setPhoneError("Please enter a valid South African phone number.");
            } else {
                setPhoneError("");
            }
        } else {
            setPhoneError("Please enter a phone number.");
        }
    };

    const validateEmail = (value) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (value && !emailPattern.test(value)) {
            setEmailError("Please enter a valid email address.");
        } else {
            setEmailError("");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/provider/advisorSignup`,
                {
                    firstName: formData.name,
                    lastName: formData.surname,
                    phone: formData.phone,
                    email: formData.email,
                    affiliation: formData.affiliation,
                    fspNumber: formData.fspNumber,
                    underSupervision: formData.underSupervision
                },
                {withCredentials: true}
            );
            if (response.status >= 200 && response.status < 300) {
                loadScript({
                    id: 'facebook-lead-event',
                    inlineScript: "fbq('track', 'Lead', { content_name: 'Advisor Form Submission', status: 'complete' });"
                });
                setSuccess(true);
            }
        } catch (error) {
            setLoading(false);
            // Check specifically for the message property first
            if (error.response?.data?.message) {
                setError(error.response.data.message);
            }
            // Then fall back to other error types
            else if (error.response?.data?.errors) {
                const errorMessages = Object.values(error.response.data.errors).flat();
                setError(errorMessages.join(', '));
            } else if (error.response?.data) {
                setError(error.response.data);
            } else {
                setError('Connection failed - please try again');
            }
        }
    };

    return (
        <MDBox pt={2} pb={3} px={5} mx={5}>
            {success ? (
                <MKTypography variant="h4" color="info" textAlign="center">
                    Thank you! We have received your request and will get back to you shortly.
                </MKTypography>
            ) : (
                <MDBox component="form" onSubmit={handleSubmit}>

                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <MDBox mb={2}>
                                <MKInput
                                    variant="standard"
                                    label="First Name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MDBox mb={2}>
                                <MKInput
                                    variant="standard"
                                    label="Surname"
                                    name="surname"
                                    value={formData.surname}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MDBox mb={2}>
                                <MKInput
                                    variant="standard"
                                    label="Phone"
                                    name="phone"
                                    type="tel"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    onBlur={() => validatePhoneNumber(formData.phone)}
                                    fullWidth
                                    required
                                />
                                {phoneError && (
                                    <MKTypography variant="body2" color="error">
                                        {phoneError}
                                    </MKTypography>
                                )}
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MDBox mb={2}>
                                <MKInput
                                    variant="standard"
                                    label="Email"
                                    name="email"
                                    type="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    onBlur={() => validateEmail(formData.email)}
                                    fullWidth
                                    required
                                />
                                {emailError && (
                                    <MKTypography variant="body2" color="error">
                                        {emailError}
                                    </MKTypography>
                                )}
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MDBox mb={2}>
                                <FormControl variant="standard" fullWidth required>
                                    <InputLabel>Affiliation</InputLabel>
                                    <Select
                                        name="affiliation"
                                        value={formData.affiliation || "tied"}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="tied">Tied Agent</MenuItem>
                                        <MenuItem value="independent">Independent</MenuItem>
                                    </Select>
                                </FormControl>
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MDBox mb={2}>
                                <MKInput
                                    variant="standard"
                                    label="FSP Number Your Are Linked To"
                                    name="fspNumber"
                                    value={formData.fspNumber}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12}>
                            <MDBox mb={2}>
                                <MKTypography variant="body2" mb={1}>
                                    Are you under supervision?
                                </MKTypography>
                                <RadioGroup
                                    row
                                    name="underSupervision"
                                    value={formData.underSupervision}
                                    onChange={handleChange}
                                    required
                                >
                                    <FormControlLabel value="yes" control={<Radio/>} label="Yes"/>
                                    <FormControlLabel value="no" control={<Radio/>} label="No"/>
                                </RadioGroup>
                            </MDBox>
                        </Grid>
                        <Grid item xs={12}>
                            <MDBox mb={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="isAccreditedAdvisor"
                                            checked={formData.isAccreditedAdvisor}
                                            onChange={(e) => setFormData(prev => ({
                                                ...prev,
                                                isAccreditedAdvisor: e.target.checked
                                            }))}
                                            required
                                        />
                                    }
                                    label={
                                        <MKTypography variant="body2">
                                            I declare that I am a financial advisor accredited to sell long-term
                                            insurance and retirement annuity products
                                        </MKTypography>
                                    }
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12}>
                            {error && (
                                <MDBox mb={2}>
                                    <Alert severity="error">
                                        {error}
                                    </Alert>
                                </MDBox>
                            )}
                            <MDBox mt={4} mb={1}>
                                <MKButton
                                    type="submit"
                                    variant="gradient"
                                    color="info"
                                    fullWidth
                                    disabled={loading || !!phoneError || !!emailError}
                                >
                                    {loading ? (
                                        <CircularProgress size={24} color="inherit"/>
                                    ) : (
                                        "Submit"
                                    )}
                                </MKButton>

                            </MDBox>
                        </Grid>

                    </Grid>
                </MDBox>
            )}
        </MDBox>
    );
};

export default AdvisorForm;